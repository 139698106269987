import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useSESalarySlabItemsService = () => {
    const getSESalarySlabItems = async () => {
        try {
            const response = await axiosAuth.get(`/api/SESalarySlabItems`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addSESalarySlabItems = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/SESalarySlabItems`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateSESalarySlabItems = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/SESalarySlabItems?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteSESalarySlabItems = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/SESalarySlabItems/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getSESalarySlabItems, addSESalarySlabItems, updateSESalarySlabItems, softDeleteSESalarySlabItems };
};

export default useSESalarySlabItemsService;
