import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useABMFieldExpensesService = () => {
    const getABMFieldExpenses = async () => {
        try {
            const response = await axiosAuth.get(`/api/ABMFieldExpenses`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addABMFieldExpenses = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/ABMFieldExpenses`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateABMFieldExpenses = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/ABMFieldExpenses?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteABMFieldExpenses = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/ABMFieldExpenses/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getABMFieldExpenses, addABMFieldExpenses, updateABMFieldExpenses, softDeleteABMFieldExpenses };
};

export default useABMFieldExpensesService;
