import React from 'react';

import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const usePurchaseRecordService = () => {
    const getPurchaseRecord = async (skip, take) => {
        try {
            const response = await axiosAuth.get(`/api/PurchaseRecords?skip=${skip}&take=${take}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getPurchaseRecordById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/PurchaseRecords/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addPurchaseRecord = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/PurchaseRecords`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updatePurchaseRecord = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/PurchaseRecords?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deletePurchaseRecord = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/PurchaseRecords?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        getPurchaseRecord,
        addPurchaseRecord,
        updatePurchaseRecord,
        deletePurchaseRecord,
        getPurchaseRecordById
    };
};

export default usePurchaseRecordService;
