import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useTMPRIsService = () => {
    const getTMPRIs = async () => {
        try {
            const response = await axiosAuth.get(`/api/TMPRIs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addTMPRIs = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/TMPRIs`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateTMPRIs = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/TMPRIs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteTMPRIs = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/TMPRIs/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getTMPRIs, addTMPRIs, updateTMPRIs, softDeleteTMPRIs };
};

export default useTMPRIsService;
