import { useRoutes } from 'react-router-dom';
import { useReducer, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import config from 'config';

import Dashboard from 'views/dashboard/Default';
import Categories from 'views/masters/products/categories';
import Employees from 'views/employee';
import Packs from 'views/masters/products/packs';
import SalesRecords from 'views/sales/sales-records';
import PurchaseRecord from 'views/purchase/purchase-records';
import ABMSalarySlab from 'views/salary-slabs/abm';
import DBMSalarySlab from 'views/salary-slabs/dbm';
import GCSalarySlab from 'views/salary-slabs/gc';
import GLSalarySlab from 'views/salary-slabs/gl';
import SESalarySlab from 'views/salary-slabs/se';
import TMSalarySlab from 'views/salary-slabs/tm';

//import PurchaseDetails from 'views/Purchase/PurchaseRecords/PurchaseDetails';
//import PackDetails from 'views/masters/products/Packs/PackDetails';

// login routing
const Login = Loadable(lazy(() => import('views/login')));
const ForgotPassword = Loadable(lazy(() => import('views/login/forgot-password')));

// master routing
const Products = Loadable(lazy(() => import('views/masters/products')));
const PackDetails = Loadable(lazy(() => import('views/masters/products/packs/PackDetails')));
const SalesDetails = Loadable(lazy(() => import('views/sales/sales-records/SalesDetails')));
const PurchaseDetails = Loadable(lazy(() => import('views/purchase/purchase-records/PurchaseDetails')));
//const Packs = Loadable(lazy(() => import('views/masters/products/Packs')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, role, displayRole } = useSelector((state) => state.userReducer);
    //const userId = 1;
    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: userId > 0 ? <Dashboard /> : <Navigate to="/login" />
            },
            {
                path: '/products',
                element: userId > 0 ? <Products /> : <Navigate to="/login" />
            },
            {
                path: '/packs',
                element: userId > 0 ? <Packs /> : <Navigate to="/login" />
            },
            {
                path: '/categories',
                element: userId > 0 ? <Categories /> : <Navigate to="/login" />
            },
            {
                path: '/employees',
                element: userId > 0 ? <Employees /> : <Navigate to="/login" />
            },
            {
                path: '/sales',
                element: userId > 0 ? <SalesRecords /> : <Navigate to="/login" />
            },
            {
                path: '/purchase',
                element: userId > 0 ? <PurchaseRecord /> : <Navigate to="/login" />
            },
            {
                path: '/pack-details',
                element: userId > 0 ? <PackDetails /> : <Navigate to="/login" />
            },
            {
                path: '/sales-details',
                element: userId > 0 ? <SalesDetails /> : <Navigate to="/login" />
            },
            {
                path: '/purchase-details',
                element: userId > 0 ? <PurchaseDetails /> : <Navigate to="/login" />
            },
            {
                path: '/abm',
                element: userId > 0 ? <ABMSalarySlab /> : <Navigate to="/login" />
            },
            {
                path: '/dbm',
                element: userId > 0 ? <DBMSalarySlab /> : <Navigate to="/login" />
            },
            {
                path: '/gc',
                element: userId > 0 ? <GCSalarySlab /> : <Navigate to="/login" />
            },
            {
                path: '/gl',
                element: userId > 0 ? <GLSalarySlab /> : <Navigate to="/login" />
            },
            {
                path: '/se',
                element: userId > 0 ? <SESalarySlab /> : <Navigate to="/login" />
            },
            {
                path: '/tm',
                element: userId > 0 ? <TMSalarySlab /> : <Navigate to="/login" />
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
