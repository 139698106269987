import React, { useState } from 'react';
//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import PaymentIcon from '@mui/icons-material/Payment';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import useTMFieldExpensesService from 'services/useTMFieldExpensesService';

const TMFieldExpenses = (props) => {
    //Constants
    const { onSave, onCancel, selectedTMFieldExpenses } = props;
    const { addTMFieldExpenses, updateTMFieldExpenses } = useTMFieldExpensesService();

    //States
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: selectedTMFieldExpenses.id,
            tmSalarySlabId: 1,
            unitMin: selectedTMFieldExpenses.unitMin,
            unitMax: selectedTMFieldExpenses.unitMax,
            fieldExpenses: selectedTMFieldExpenses.fieldExpenses
        },
        validationSchema: Yup.object({
            unitMin: Yup.number(),
            unitMax: Yup.number(),
            fieldExpenses: Yup.number()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedTMFieldExpenses.id != 0) {
                let data = {
                    tmSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    fieldExpenses: values.fieldExpenses
                };
                updateTMFieldExpenses(selectedTMFieldExpenses.id, data)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                let data = {
                    tmSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    fieldExpenses: values.fieldExpenses
                };
                addTMFieldExpenses(data)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedTMFieldExpenses.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <PaymentIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New TM Field Expenses</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <PaymentIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit TM Field Expenses Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="unitMin"
                                label="Unit Min"
                                onChange={formik.handleChange}
                                value={formik.values.unitMin}
                                fullWidth
                                error={formik.touched.unitMin && Boolean(formik.errors.unitMin)}
                                helperText={formik.touched.unitMin && formik.errors.unitMin}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="unitMax"
                                label="Unit Max"
                                onChange={formik.handleChange}
                                value={formik.values.unitMax}
                                fullWidth
                                error={formik.touched.unitMax && Boolean(formik.errors.unitMax)}
                                helperText={formik.touched.unitMax && formik.errors.unitMax}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="fieldExpenses"
                                label="Field Expenses"
                                onChange={formik.handleChange}
                                value={formik.values.fieldExpenses}
                                fullWidth
                                error={formik.touched.fieldExpenses && Boolean(formik.errors.fieldExpenses)}
                                helperText={formik.touched.fieldExpenses && formik.errors.fieldExpenses}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default TMFieldExpenses;
