//import React from 'react';

import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useSalesRecordService = () => {
    const getSalesRecord = async (skip, take) => {
        try {
            const response = await axiosAuth.get(`/api/SalesRecords?skip=${skip}&take=${take}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalesRecordById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/SalesRecords/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    // const getSaleByRecord = async (skip, take) => {
    //     try {
    //         const response = await axiosAuth.get(`/api/SalesRecords?skip=${skip}&take=${take}`);
    //         return handleResponse(response);
    //     } catch (error) {
    //         return handleError(error);
    //     }
    // };

    // const searchSalesRecord = async (skip, take, recordedBy, startDate, endDate) => {
    //     try {
    //         const response = await axiosAuth.get(
    //             `/api/SalesRecords?skip=${skip}&take=${take}&RecordedBy=${recordedBy}&StartDate=${startDate}&EndDate=${endDate}`
    //         );
    //         return handleResponse(response);
    //     } catch (error) {
    //         return handleError(error);
    //     }
    // };

    const addSalesRecord = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/SalesRecords`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateSalesRecord = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/SalesRecords?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deleteSalesRecord = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/SalesRecords?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        getSalesRecord,
        addSalesRecord,
        updateSalesRecord,
        deleteSalesRecord,
        getSalesRecordById
        //searchSalesRecord,
        // getSaleByRecord
    };
};

export default useSalesRecordService;
