import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useGCSalarySlabItemsService = () => {
    const getGCSalarySlabItems = async () => {
        try {
            const response = await axiosAuth.get(`/api/GCSalarySlabItems`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addGCSalarySlabItems = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/GCSalarySlabItems`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateGCSalarySlabItems = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/GCSalarySlabItems?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteGCSalarySlabItems = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/GCSalarySlabItems/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getGCSalarySlabItems, addGCSalarySlabItems, updateGCSalarySlabItems, softDeleteGCSalarySlabItems };
};

export default useGCSalarySlabItemsService;
