// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate
};

const adminMenuItems = [
    {
        id: 'admin-main-pages',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: icons.IconDashboard,
                url: '/',
                breadcrumbs: false
            },
            {
                id: 'employees',
                title: 'Employees',
                type: 'item',
                icon: icons.IconUsers,
                url: '/employees',
                breadcrumbs: false
            },
            {
                id: 'master',
                title: 'Products Master',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'categories',
                        title: 'Categories',
                        type: 'item',
                        url: '/categories',
                        breadcrumbs: false
                    },
                    {
                        id: 'products',
                        title: 'Products',
                        type: 'item',
                        url: '/products',
                        breadcrumbs: false
                    },
                    {
                        id: 'packs',
                        title: 'Packs',
                        type: 'item',
                        url: '/packs',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'sales',
                title: 'Sales',
                type: 'item',
                icon: icons.IconFileInvoice,
                url: '/sales',
                breadcrumbs: false
            },
            {
                id: 'purchase',
                title: 'Purchase',
                type: 'item',
                icon: icons.IconUsers,
                url: '/purchase',
                breadcrumbs: false
            },

            {
                id: 'salry-slabs',
                title: 'Salary Slabs',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'abm',
                        title: 'ABM',
                        type: 'item',
                        url: '/abm',
                        breadcrumbs: false
                    },
                    {
                        id: 'dbm',
                        title: 'DBM',
                        type: 'item',
                        url: '/dbm',
                        breadcrumbs: false
                    },
                    {
                        id: 'gc',
                        title: 'GC',
                        type: 'item',
                        url: '/gc',
                        breadcrumbs: false
                    },
                    {
                        id: 'gl',
                        title: 'GL',
                        type: 'item',
                        url: '/gl',
                        breadcrumbs: false
                    },
                    {
                        id: 'se',
                        title: 'SE',
                        type: 'item',
                        url: '/se',
                        breadcrumbs: false
                    },
                    {
                        id: 'tm',
                        title: 'TM',
                        type: 'item',
                        url: '/tm',
                        breadcrumbs: false
                    }
                    // {
                    //     id: 'categories',
                    //     title: 'Categories',
                    //     type: 'item',
                    //     url: '/categories',
                    //     breadcrumbs: false
                    // }
                ]
            }
        ]
    }
    // {
    //     id: 'main-settings',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'setting-pages',
    //             title: 'Settings',
    //             type: 'collapse',
    //             icon: icons.IconSettings,
    //             breadcrumbs: false,
    //             children: [
    //                 // {
    //                 //     id: 'bill-items',
    //                 //     title: 'Bill Items',
    //                 //     type: 'item',
    //                 //     url: '/bill-items',
    //                 //     breadcrumbs: false
    //                 // },
    //                 // {
    //                 //     id: 'opd-receipt-item',
    //                 //     title: 'OPD Receipt Items',
    //                 //     type: 'item',
    //                 //     url: '/opd-receipt-item-master',
    //                 //     breadcrumbs: false
    //                 // }
    //             ]
    //         }
    //     ]
    // }
];

export default adminMenuItems;
