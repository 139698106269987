import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useEmployeeService = () => {
    const getEmployee = async (skip, take) => {
        try {
            const response = await axiosAuth.get(`/api/Users/paginated-list?skip=${skip}&take=${take}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getEmployeeById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Users?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addEmployee = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Users`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateEmployee = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Users?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteEmployee = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Users/soft-delete?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return { getEmployee, addEmployee, updateEmployee, softDeleteEmployee, getEmployeeById };
};

export default useEmployeeService;
