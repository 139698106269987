import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useDBMFieldExpensesService = () => {
    const getDBMFieldExpenses = async () => {
        try {
            const response = await axiosAuth.get(`/api/DBMFieldExpenses`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addDBMFieldExpenses = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/DBMFieldExpenses`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateDBMFieldExpenses = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/DBMFieldExpenses?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteDBMFieldExpenses = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/DBMFieldExpenses/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getDBMFieldExpenses, addDBMFieldExpenses, updateDBMFieldExpenses, softDeleteDBMFieldExpenses };
};

export default useDBMFieldExpensesService;
