// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const borderRadius = 3;

export const roles = [
    'Admin',
    'Regional Sales Manager (RM)',
    'Divisional Sales Manager (DM)',
    'Branch Manager (BM)',
    'Assistant Branch Manager (ABM)',
    'Team Manager (TM)',
    'Group Leader (GL)',
    'Group Captain (GC)',
    'Sales Executive (SE)'
];
