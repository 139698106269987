import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

//MaterialUI Imports
import {
    Button,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Stack,
    Alert,
    Snackbar,
    Pagination,
    Grid,
    Card
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';

//import axios from 'axios';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import EmployeeForm from './EmployeeForm';
import useEmployeeService from 'services/useEmployeeService';
import NoResults from 'assets/images/noresults.png';
import { convertDateToLocal } from 'utils/DateOperations';

const Employees = () => {
    //Constants
    const theme = useTheme();
    const { getEmployee, softDeleteEmployee } = useEmployeeService();
    const [searchParams, setSearchParams] = useSearchParams();
    const pageSize = 50;

    //States
    const [employeeDetails, setEmployeeDetails] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');

    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);

    const [employeeCount, setEmployeeCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSkipValue, setCurrentSkipValue] = useState(0);
    const [isError, setIsError] = useState();

    //Methods
    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getEmployeeList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getEmployeeList = () => {
        setEmployeeDetails(undefined);
        getEmployee(currentSkipValue, pageSize)
            .then((response) => {
                console.log(response);
                setEmployeeCount(response.data.count);
                setEmployeeDetails(response.data);
            })
            .catch((error) => setIsError(error.message));
    };

    const handleDelete = () => {
        softDeleteEmployee(selectedEmployee.id)
            .then((response) => {
                getEmployeeList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    //useEffects
    useEffect(() => {
        getEmployeeList();
    }, []);

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<PeopleIcon color="primary" />}
                    sx={{ padding: 0 }}
                    title="Employee List"
                    action={
                        <Grid container>
                            <Grid item sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    align="right"
                                    onClick={() => {
                                        setSelectedEmployee({
                                            id: 0,
                                            salutation: 0,
                                            firstName: '',
                                            middleName: '',
                                            lastName: '',
                                            email: '',
                                            mobileNumber: ''
                                        });
                                        setOpenForm(true);
                                    }}
                                >
                                    Add New Employee
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
                <Divider sx={{ marginY: 3 }} />
                <Grid item align="right" sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        align="right"
                        onClick={() => {
                            setSelectedEmployee({
                                id: 0,
                                salutation: 0,
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                email: '',
                                mobileNumber: ''
                            });
                            setOpenForm(true);
                        }}
                    >
                        Add New Employee
                    </Button>
                </Grid>

                {employeeDetails != undefined && (
                    <>
                        {employeeCount != undefined && employeeCount != 0 ? (
                            <Pagination
                                count={Math.ceil(employeeCount / pageSize)}
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                color="primary"
                                sx={{ marginY: 2 }}
                                onChange={(event, page) => {
                                    setCurrentSkipValue((page - 1) * pageSize);
                                    setCurrentPage(page);
                                }}
                            />
                        ) : null}
                    </>
                )}

                {employeeDetails != undefined || employeeDetails != null ? (
                    <>
                        {employeeDetails.users?.length == 0 ? (
                            <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                <img src={NoResults} alt="No Result" width="150px" />
                                <Typography variant="h4" fontWeight={100}>
                                    There are no employee details found
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                    <TableContainer>
                                        <Table
                                            sx={{
                                                border: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableCell sx={{ width: 70 }}>
                                                    <Typography variant="h5">Sr.No.</Typography>{' '}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h5">Employee</Typography>{' '}
                                                </TableCell>
                                                {/* <TableCell sx={{ width: 100 }}>
                                                    <Typography variant="h5">Role</Typography>
                                                </TableCell> */}
                                                <TableCell sx={{ width: 120 }}>
                                                    <Typography variant="h5">Email Address</Typography>
                                                </TableCell>
                                                {/* <TableCell sx={{ width: 120 }}>
                                                    <Typography variant="h5">Mobile Number</Typography>
                                                </TableCell> */}
                                                <TableCell sx={{ width: 120 }}>
                                                    <Typography variant="h5">Joining Date</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 180 }}>
                                                    <Typography variant="h5">Reporting To</Typography>
                                                </TableCell>

                                                <TableCell sx={{ width: 110 }}></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {employeeDetails.users?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ color: theme.palette.grey[800], fontWeight: '500' }}
                                                            >
                                                                {row.roleDisplay}
                                                            </Typography>
                                                            <Typography sx={{ color: theme.palette.primary.main, fontWeight: '700' }}>
                                                                {row.salutationDisplay} {row.firstName} {row.middleName} {row.lastName}
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                                                                {row.mobileNumber}
                                                            </Typography>
                                                        </TableCell>
                                                        {/* <TableCell align="left">{row.role}</TableCell> */}
                                                        <TableCell align="left">{row.email}</TableCell>
                                                        {/* <TableCell align="left">{row.mobileNumber}</TableCell> */}
                                                        <TableCell align="left">{convertDateToLocal(row.joiningDate)}</TableCell>
                                                        <TableCell align="left">{row.reportingToName}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Edit Employee details">
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedEmployee(row);
                                                                        setOpenForm(true);
                                                                    }}
                                                                >
                                                                    <EditIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Employee">
                                                                <IconButton
                                                                    size="small"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        setSelectedEmployee(row);
                                                                        setOpenDeleteDialog(true);
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {employeeDetails.users?.map((row, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sx={{ mb: 0.5, display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                    >
                                        <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="h5">Name:</Typography>
                                                <Typography>
                                                    {' '}
                                                    {row.salutationDisplay} {row.firstName} {row.middleName} {row.lastName}
                                                </Typography>
                                            </Stack>

                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Role:</Typography>
                                                <Typography>{row.role}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Email Address:</Typography>
                                                <Typography>{row.email}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Mobile Number:</Typography>
                                                <Typography>{row.mobileNumber}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Joining Date:</Typography>
                                                <Typography>{new Date(row.joiningDate).toLocaleDateString('hi-IN')}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Reporting To:</Typography>
                                                <Typography>{row.reportingTo}</Typography>
                                            </Stack>

                                            <Divider sx={{ mt: 1 }} />
                                            <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                <Tooltip title="Edit Employee details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedEmployee(row);
                                                            setOpenForm(true);
                                                        }}
                                                    >
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Employee">
                                                    <IconButton
                                                        size="small"
                                                        color="error"
                                                        onClick={() => {
                                                            setSelectedEmployee(row);
                                                            setOpenDeleteDialog(true);
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                        <Typography variant="h4" textAlign="center">
                            Getting Employee List...
                        </Typography>
                        <CircularProgress />
                    </Stack>
                )}
            </MainCard>

            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="md">
                <DialogContent>
                    <EmployeeForm
                        employees={employeeDetails?.users}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        selectedEmployee={selectedEmployee}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Employee Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this employee details ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast} severity="success" sx={{ width: '100%', backgroundColor: theme.palette.success.main }}>
                    <Typography sx={{ color: theme.palette.grey[50] }}> Employee data deleted succesfully!!</Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default Employees;
