import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useTMFieldExpensesService = () => {
    const getTMFieldExpenses = async () => {
        try {
            const response = await axiosAuth.get(`/api/TMFieldExpenses`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addTMFieldExpenses = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/TMFieldExpenses`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateTMFieldExpenses = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/TMFieldExpenses?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteTMFieldExpenses = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/TMFieldExpenses/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getTMFieldExpenses, addTMFieldExpenses, updateTMFieldExpenses, softDeleteTMFieldExpenses };
};

export default useTMFieldExpensesService;
